export function getFiltersDataTypesFromSettings(settings: {
	[key: string]: any;
}): { id: string; value: string }[] {
	const dataTypeIds: Record<string, string> = {};

	for (const key in settings) {
		if (key && key.includes('adtldatatype_') && key.includes('_filter_result_set')) {
			// regex to find the number between adtldatatype_ and _filter_result_set
			const dataTypeId = key.match(/adtldatatype_(\d+)_filter_result_set/);
			if (dataTypeId && dataTypeId.length > 1) {
				// @ts-ignore
				dataTypeIds[dataTypeId[1]] = settings[key] as string;
			}
		}
	}

	return Object.keys(dataTypeIds).map(id => ({ id, value: dataTypeIds[id] }));
}
