import { Params } from '@angular/router';

interface TitleTransformConfig {
	title?: string;
	queryParams?: Params;
	params?: Params;
	setAdditionalDataTypes?: Record<string, { id: string; name: string }[]>;
	totalCount?: number;
}

/**
 * This function sets the title of a list page, replacing the route parameters with the actual values.
 *
 * @param config
 */
export const getTitleWithReplacements = (config: TitleTransformConfig) => {
	const { title, setAdditionalDataTypes, params, queryParams, totalCount } = config;

	if (!title) return '';

	// if there are no replacements, return the title as is
	if (!title.includes('{{') && !title.includes('}}')) return title;

	if (!setAdditionalDataTypes && !queryParams && !totalCount) return title;

	let newTitle = title;

	for (const key in queryParams) {
		const toBeReplacedUppercase = `{{${key.toUpperCase()}.NAME}}`;
		const toBeReplacedLowercase = `{{${key.toLowerCase()}.name}}`;

		newTitle = newTitle.replace(toBeReplacedUppercase, queryParams[key]);
		newTitle = newTitle.replace(toBeReplacedLowercase, queryParams[key]);
	}

	if (setAdditionalDataTypes) {
		for (const dataTypeKey in setAdditionalDataTypes) {
			let dataTypeName: string | null = null;

			if (params && params['id']) {
				const id = params['id'];
				const dataType = setAdditionalDataTypes[dataTypeKey]?.find(dataType => dataType.id === id);
				dataTypeName = dataType?.name || null;
			}

			if (!dataTypeName) {
				dataTypeName = setAdditionalDataTypes[dataTypeKey]?.[0]?.name || '';
			}

			const toBeReplacedU = `{{${dataTypeKey.toUpperCase()}.NAME}}`;
			const toBeReplacedL = `{{${dataTypeKey.toLowerCase()}.name}}`;

			newTitle = newTitle.replace(toBeReplacedU, dataTypeName);
			newTitle = newTitle.replace(toBeReplacedL, dataTypeName);
		}
	}

	// if there are still replacements left, we replace them with span

	const toBeReplacedUppercase = /{{[A-Z0-9_]+.NAME}}/g;
	const toBeReplacedLowercase = /{{[a-z0-9_]+.name}}/g;
	// newTitle = newTitle.replace(toBeReplacedUppercase, '<span class="text-loading-skeleton"></span>');
	// newTitle = newTitle.replace(toBeReplacedLowercase, '<span class="text-loading-skeleton"></span>');
	newTitle = newTitle.replace(toBeReplacedUppercase, '');
	newTitle = newTitle.replace(toBeReplacedLowercase, '');

	// replace search in title
	const searchParam = queryParams?.['search'] || '';
	newTitle = newTitle.replace('{{search}}', searchParam);
	newTitle = newTitle.replace('{{SEARCH}}', searchParam);

	// replace total count in title
	newTitle = newTitle.replace('{{content.total}}', totalCount?.toString() || '');

	return newTitle;
};
