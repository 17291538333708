import { NatsBlock } from '@nats/models';

/*
 * Is used to get the width of the thumbnail image that is used in the set list.
 *
 * This injection depends on BLOCK_DATA token
 */
export const getThumbWidth = (blockData: NatsBlock): number => {
	const DEFAULT_WIDTH = 800;

	if (!blockData) return DEFAULT_WIDTH;

	const thumbnailsPerRow = (blockData.settings as NatsBlock['settings'] & ThumbnailsPerRow)
		?.thumbnails_per_row;

	if (!thumbnailsPerRow) return DEFAULT_WIDTH;

	const columnsToWidth: Record<number, number> = {
		'1': 800,
		'2': 800,
		'3': 800,
		'4': 400,
		'6': 200,
	};

	return columnsToWidth[+thumbnailsPerRow] || DEFAULT_WIDTH;
};

interface ThumbnailsPerRow {
	thumbnails_per_row?: string; // "1", "2", "3", "4", "6"
}

/*
 * Is used to inject the width of the thumbnail image that is used in the set list.
 *
 * This injection depends on 2 dependencies:
 * 1. The current screen size from injectCurrentScreenSize();
 * 2. The page design layout from inject(PAGE_DESIGN_LAYOUT);
 */
// export const injectThumbWidth = (blockId: string): Observable<number> => {
// 	const currentScreenSize$ = injectCurrentScreenSize();
// 	const pageSettingsDesignLayout = inject(PAGE_DESIGN_LAYOUT);
//
// 	const DEFAULT_WIDTH = 800;
//
// 	const columnsToWidth: Record<number, number> = {
// 		1: 800,
// 		2: 800,
// 		3: 800,
// 		4: 400,
// 		6: 200,
// 		7: 200,
// 		8: 200,
// 		9: 200,
// 		10: 200,
// 		11: 200,
// 		12: 200,
// 	};
//
// 	const { desktop, phone } = pageSettingsDesignLayout.layouts || {};
// 	if (!desktop || !phone) return of(DEFAULT_WIDTH);
//
// 	return currentScreenSize$.pipe(
// 		map(screenSize => {
// 			if (screenSize === SizeScreenType.Phone) {
// 				const phoneSettings = phone.find(block => block.id === blockId);
// 				return phoneSettings ? columnsToWidth[phoneSettings.w] : DEFAULT_WIDTH;
// 			}
//
// 			const desktopSettings = desktop.find(block => block.id === blockId);
// 			return desktopSettings ? columnsToWidth[desktopSettings.w] : DEFAULT_WIDTH;
// 		})
// 	);
// };
