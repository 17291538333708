import { NatsSiteData } from '@nats/models';

export function getDataTypeListThumbUrl(
	dataType: any,
	config: NatsSiteData,
	dataDetailId: string
): string {
	if (!dataType.data_detail_values) return '';
	const detailValue = dataType.data_detail_values[dataDetailId];
	if (!detailValue) return '';
	const contentServers = detailValue.content['image'].servers;
	const server = contentServers.filter((x: any) => !!x.signature);
	if (server.length === 0) return '';
	const { signature, cms_content_server_id } = server[0];
	const contentServerUrl = config.servers[cms_content_server_id].settings.url;

	let fullPath = contentServerUrl + detailValue.path + detailValue.content['image'].filename;

	if (signature) fullPath += '?' + signature;
	return fullPath;
}
