import { DatePipe } from '@angular/common';

type VideoLengthFormat =
	| 'enabled'
	| 'enabled_hm'
	| 'enabled_ms'
	| 'enabled_m'
	| 'enabled_txt_hms'
	| 'enabled_txt_hs'
	| 'enabled_txt_ms'
	| 'enabled_txt_m'
	| 'disabled';

/**
 * Converts seconds to a format
 * @param seconds
 * @param format
 * @param datePipe
 */
export const convertSecondsToFormat = (
	seconds: number,
	format: VideoLengthFormat,
	datePipe: DatePipe
) => {
	const secondsDate = new Date(0, 0, 0, 0, 0, seconds);

	switch (format) {
		case 'enabled':
			return replaceZerosWithEmptyString(datePipe.transform(secondsDate, 'HH:mm:ss'));
		case 'enabled_hm':
			return replaceZerosWithEmptyString(datePipe.transform(secondsDate, 'HH:mm'));
		case 'enabled_ms':
			return replaceZerosWithEmptyString(datePipe.transform(secondsDate, 'mm:ss'));
		case 'enabled_m':
			return datePipe.transform(secondsDate, 'mm');
		case 'enabled_txt_hms':
			return convertSecondsToHoursMinutesSeconds(seconds);
		case 'enabled_txt_hs':
			return convertSecondsToHoursMinutes(seconds);
		case 'enabled_txt_ms':
			return convertSecondsToMinutesSeconds(seconds);
		case 'enabled_txt_m':
			return `${Math.floor(Number(seconds) / 60)}<span class="t-min">min</span>`;
		case 'disabled':
		default:
			return '';
	}
};

function replaceZerosWithEmptyString(value: string | null) {
	if (!value) return '';

	// case 11:00:00 => 11:00:00 (no change)
	// case 30:30 => 30:30 (no change)
	if (!value.startsWith('0')) return value;

	// case 00:30 => 0:30 (remove 0)
	// case 03:03 => 3:03 (remove 0)
	if (value.length === 5) return value.slice(1); // remove 0

	// case 00:00:03 => 0:03 (remove 00:0)
	// case 00:00:30 => 0:30 (remove 00:0)
	// case 00:03:30 => 3:30 (remove 00:0)
	if (value.startsWith('00:0')) return value.slice(4); // remove 00:0

	// case 00:30:00 => 30:00 (remove 00:)
	if (value.startsWith('00:')) return value.slice(3); // remove 00:

	// case 01:00:00 => 1:00:00 (remove 0)
	return value.slice(1); // remove 0
}

/**
 * format: enabled_txt_hms
 * Converts seconds to minutes (4500sec => 1h 15min 45sec)
 * @param seconds
 */
function convertSecondsToHoursMinutesSeconds(seconds: number | string) {
	const hours = Math.floor(Number(seconds) / 3600);
	const minutes = Math.floor((Number(seconds) - hours * 3600) / 60);
	const secondsLeft = Math.floor(Number(seconds) - hours * 3600 - minutes * 60);

	if (hours === 0) {
		if (minutes === 0) {
			return `${secondsLeft}<span class="t-second">sec</span>`;
		}
		return `${minutes}<span class="t-min">min</span> ${secondsLeft}<span class="t-second">sec</span>`;
	} else {
		return `${hours}<span class="t-hour">h</span> ${minutes}<span class="t-min">min</span> ${secondsLeft}<span class="t-second">sec</span>`;
	}
}

/**
 * format: enabled_txt_hs
 * Converts seconds to minutes (4500sec => 1h 15min)
 * @param seconds
 */
function convertSecondsToHoursMinutes(seconds: number | string) {
	const hours = Math.floor(Number(seconds) / 3600);
	const minutes = Math.floor((Number(seconds) - hours * 3600) / 60);
	return hours === 0
		? `${minutes}<span class="t-min">min</span>`
		: `${hours}<span class="t-hour">h</span> ${minutes}<span class="t-min">min</span>`;
}

/**
 * format: enabled_txt_ms
 * Converts seconds to minutes (75sec => 1min 15sec)
 * @param seconds
 */
function convertSecondsToMinutesSeconds(seconds: number | string) {
	const minutes = Math.floor(Number(seconds) / 60);
	const secondsLeft = Math.floor(Number(seconds) - minutes * 60);
	return minutes === 0
		? `${secondsLeft}<span class="t-second">sec</span>`
		: `${minutes}<span class="t-min">min</span> ${secondsLeft}<span class="t-second">sec</span>`;
}
