import { NatsSet } from '@nats/models';

export interface MediaAreaSettings {
	show: boolean;
	showVideoPlayer: boolean;
	showPhotosGrid: boolean;
}

export type ContentAreaASettings =
	| string
	| 'donotshow'
	| 'videos_only'
	| 'photos_only'
	| 'video_priority'
	| 'photo_priority';

export type ContentAreaBSettings =
	| string
	| 'donotshow'
	| 'videos_only'
	| 'photos_only'
	| 'unused_type_area_1';

export const getMediaAAreaSettings = (
	type: ContentAreaASettings,
	set: NatsSet
): MediaAreaSettings => {
	const { image, video, video_trailer } = set.content_count;

	const show = type !== 'donotshow';

	if (type === 'videos_only') {
		return { show, showVideoPlayer: true, showPhotosGrid: false };
	}

	if (type === 'photos_only') {
		return { show, showVideoPlayer: false, showPhotosGrid: true };
	}

	if (type === 'video_priority') {
		// if there is a video, show the video player, otherwise show the photos grid
		const showVideoPlayer = parseInt(video) > 0 || parseInt(video_trailer) > 0; // if there is a video, show the video player
		return { show, showVideoPlayer, showPhotosGrid: !showVideoPlayer };
	}

	if (type === 'photo_priority') {
		// if there is a photo, show the photos grid, otherwise show the video player
		const showPhotosGrid = parseInt(image) > 0; // if there is a photo, show the photos grid
		return { show, showVideoPlayer: !showPhotosGrid, showPhotosGrid };
	}

	return { show, showVideoPlayer: false, showPhotosGrid: false };
};

export const getMediaBAreaSettings = (
	type: ContentAreaBSettings,
	areaASettings: MediaAreaSettings
): MediaAreaSettings => {
	const show = type !== 'donotshow';

	if (type === 'videos_only') {
		return { show, showVideoPlayer: true, showPhotosGrid: false };
	}

	if (type === 'photos_only') {
		return { show, showVideoPlayer: false, showPhotosGrid: true };
	}

	if (type === 'unused_type_area_1') {
		if (areaASettings.showVideoPlayer) {
			return { show, showVideoPlayer: false, showPhotosGrid: true };
		}
		return { show, showVideoPlayer: true, showPhotosGrid: false };
	}

	return { show, showVideoPlayer: false, showPhotosGrid: false };
};
