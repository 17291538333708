import { SizeScreenType } from './nats-page.model';

export const BREAKPOINT_MAP = {
	[SizeScreenType.Desktop]: '(min-width: 767px)',
	// [SizeScreenType.Tablet]: '(min-width: 768px) and (max-width: 991px)',
	[SizeScreenType.Phone]: '(min-width: 0px) and (max-width: 767px)',
};

export type BreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const BOOTSTRAP_BREAKPOINT_MAP: Record<BreakpointType, string> = {
	xs: '(min-width: 0) and (max-width: 576px)',
	sm: '(min-width: 576px) and (max-width: 768px)',
	md: '(min-width: 768px) and (max-width: 992px)',
	lg: '(min-width: 992px) and (max-width: 1200px)',
	xl: '(min-width: 1200px) and (max-width: 1400px)',
	xxl: '(min-width: 1400px)',
};

export const THUMB_HEIGHTS = {
	short: 150,
	medium: 225,
	tall: 300,
	extratall: 400,
};
