import { Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { NatsBlock } from './nats-block.model';

export interface NatsPageData {
	cms_page_id: string;
	slug: string;
	type: string;
	area_type: string;
	name: string;
	custom: string;
	display: string;
	top_nav: string;
	bottom_nav: string;
	link_order: string;
	url: string;
	html: string;
	cms_theme_id: string;
	banners: string;
	banner_count: string;
	cms_area_id: string;
	cms_area_id_nice: string;

	blocks: NatsBlock[];
	settings: {
		body_class?: string;
		body_id?: string;
		page_width?: 'full' | 'contained';

		meta_title?: string;
		meta_author?: string;
		meta_description?: string;
		meta_image_url?: string;
		meta_keywords?: string;

		userconsent_required?: 'default' | 'not_required'; // not_required - Do not require User Consent , default - check theme settings

		design_layout?: PageSettingsDesignLayout;
	} | null;
	success: boolean;
}

export interface BlockConfig<T = unknown> {
	id: string;
	pageId: string;
	dataInjector: Injector;
	component: Observable<Type<T>>;
}

export interface PageSettingsDesignLayout {
	layouts?: PageDesignLayout;
	hidden_blocks?: PageHiddenBlocks;
}

export type PageDesignLayout = { [key in SizeScreenType]: DesignLayout[] };
export type PageHiddenBlocks = { [key in SizeScreenType]: string[] };

export const enum SizeScreenType {
	Phone = 'phone',
	// Tablet = 'tablet',
	Desktop = 'desktop',
}

export const EMPTY_PAGE_DESIGN_LAYOUT: PageDesignLayout = {
	[SizeScreenType.Phone]: [],
	// [SizeScreenType.Tablet]: [],
	[SizeScreenType.Desktop]: [],
};

export const EMPTY_PAGE_HIDDEN_BLOCKS: PageHiddenBlocks = {
	[SizeScreenType.Phone]: [],
	// [SizeScreenType.Tablet]: [],
	[SizeScreenType.Desktop]: [],
};

export interface DesignLayout {
	id: string;
	h: number;
	w: number;
	x: number;
	y: number;
	minH: number;
	minW: number;
}
