import {
	ApplicationConfig,
	enableProdMode,
	ErrorHandler,
	importProvidersFrom,
} from '@angular/core';
import { NatsSiteData } from '@nats/models';
import {
	CONFIG_TOKEN,
	GLOBAL_CONFIG,
	NATS_CODE,
	NatsConfig,
	SITE_TYPE,
	SITE_TYPES,
} from '@nats/tokens';
import { environment } from './environments/environment';
import { addLinkTagInHead, loadTheme } from '@nats/shared/functions';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideNatsRoutesInitializer } from './app/core/routes-config.provider';
import { headersInterceptor } from './app/core/headers.interceptor';
import { contentEncodingInterceptor } from '@nats/shared/http-helpers';
import { CookieService } from 'ngx-cookie-service';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { APP_BASE_HREF } from '@angular/common';
import { NatsErrorHandler } from '@nats/shared/services';

import { provideNgxMetaCore } from '@davidlj95/ngx-meta/core';
import { provideNgxMetaRouting } from '@davidlj95/ngx-meta/routing';
import { provideNgxMetaStandard } from '@davidlj95/ngx-meta/standard';
import { provideNgxMetaOpenGraph } from '@davidlj95/ngx-meta/open-graph';
import { provideNgxMetaTwitterCard } from '@davidlj95/ngx-meta/twitter-card';

declare global {
	interface Window {
		_boot: Promise<NatsConfig>;
		_siteData: Promise<NatsSiteData>;
		_natsCode: string;
		_natsCodeCookie: string;
	}
}

if (environment.production) {
	enableProdMode();

	window.console.log = () => {
		// this will prevent the console from logging anything on production
	};
}

function configLoader(): Promise<NatsConfig> {
	return window['_boot'] as Promise<NatsConfig>;
}

function siteDataLoader(): Promise<NatsSiteData> {
	return window['_siteData'] as Promise<NatsSiteData>;
}

async function bootstrap(): Promise<void> {
	const config = await configLoader();
	const siteData = await siteDataLoader();

	console.log({ config, siteData });

	addLinkTagInHead(siteData.area.settings.url, 'preconnect');

	loadTheme(siteData);

	const appConfig: ApplicationConfig = {
		providers: [
			provideRouter(
				[], // will be populated by the routes initializer
				withInMemoryScrolling({ scrollPositionRestoration: 'top' })
			),

			provideIonicAngular(),

			provideNatsRoutesInitializer(),

			provideHttpClient(
				withInterceptors([headersInterceptor, contentEncodingInterceptor]),
				withInterceptorsFromDi() // needed for the HttpCacheInterceptorModule
			),

			// SEO meta tags
			provideNgxMetaCore(),
			provideNgxMetaRouting(),
			provideNgxMetaStandard(),
			provideNgxMetaOpenGraph(),
			provideNgxMetaTwitterCard(),

			CookieService,

			{ provide: SITE_TYPE, useValue: SITE_TYPES.TOUR },
			{ provide: CONFIG_TOKEN, useValue: config },
			{ provide: GLOBAL_CONFIG, useValue: siteData },
			{ provide: NATS_CODE, useValue: window['_natsCode'] || window['_natsCodeCookie'] },
			{ provide: APP_BASE_HREF, useValue: new URL(config.areaUrl).pathname },
			{ provide: ErrorHandler, useClass: NatsErrorHandler },

			importProvidersFrom(HttpCacheInterceptorModule.forRoot()),
		],
	};

	bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
}

bootstrap();
