import { NatsSet, NatsSiteData } from '@nats/models';
import { createContentUrl } from './create-content-url';

export function getSetClipVideoUrl(set: NatsSet, config: NatsSiteData): string | null {
	const { clip } = set.preview_formatted;
	if (!clip?.length) return null;
	return createContentUrl(clip[0], config);
}

export function getSetTrailerVideoUrl(set: NatsSet, config: NatsSiteData): string | null {
	const { trailer } = set.preview_formatted;
	if (!trailer?.formats?.length) return null;
	const { content } = trailer.formats[0];
	if (!content?.length) return null;
	return createContentUrl(content[0], config);
}
