import { NatsSiteData } from '@nats/models';
import { addLinkTagInHead } from './perf-utils';

export function loadTheme(siteData?: NatsSiteData): void {
	if (!siteData) return;

	const { theme } = siteData;

	console.log({ theme });

	const {
		area_favicon_url,
		css_body_bg_color,
		custom_css_declarations,
		body_tag_class,
		body_tag_id,
	} = theme.settings;

	const { hasVariables, cssStyle } = getCSSVariablesFromTheme(theme.settings);

	if (hasVariables) addStyleTagToHead(cssStyle, 'nats-theme-variables');

	if (area_favicon_url) addLinkTagInHead(area_favicon_url, 'icon', 'image/x-icon');

	if (css_body_bg_color) setBodyBgColor(css_body_bg_color);

	if (custom_css_declarations) addStyleTagToBody(custom_css_declarations);

	if (body_tag_class) document.body.classList.add(body_tag_class);

	if (body_tag_id) document.body.id = body_tag_id;
}

function setBodyBgColor(color: string) {
	// TODO: check this if SSR is implemented
	if (document?.body) {
		document.body.style.backgroundColor = color;
	}
}

export function addStyleTagToBody(styles: string, id?: string): void {
	if (document?.body) {
		const style = document.createElement('style');
		style.textContent = styles;
		style.id = id || 'nats-custom-styles' + '-' + Date.now();
		document.body.appendChild(style);
	}
}

export function addStyleTagToHead(styles: string, id?: string): void {
	if (document?.head) {
		const style = document.createElement('style');
		style.textContent = styles;
		style.id = id || 'nats-custom-styles' + '-' + Date.now();
		document.head.appendChild(style);
	}
}

export function getCSSVariablesFromTheme(settings: NatsSiteData['theme']['settings']) {
	const cssVariables = Object.keys(settings)
		// get only the css variables from the theme settings that start with '--' and are not null
		.filter(key => key.startsWith('--') && settings[key] !== null && settings[key] !== '')
		.reduce((acc, key) => {
			// create a string with the css variables ex. --color-primary: #fff;
			acc += `${key}: ${settings[key]};`;
			return acc;
		}, '');

	return {
		hasVariables: cssVariables.length > 0,
		cssStyle: `:root { ${cssVariables} }`, // create the css with the css variables ex. :root { --color-primary: #fff; }
	};
}
