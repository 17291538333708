import { NatsSet, NatsSiteData } from '@nats/models';
import { replacePlaceholdersInPageSlug } from './replace-placeholders-in-page-slug';

// Requested in: https://toomuchmedia.atlassian.net/browse/ACF-160
export function getModelsWithLinks(
	globalConfig: NatsSiteData,
	settings: { setlist_display_adtldatatype_4_target: string },
	set: NatsSet
): Array<{ name: string; routeLink: string | null }> {
	const models = set.data_types?.find(x => x.cms_data_type_id === '4')?.data_values ?? [];

	if (models.length === 0) return [];

	const { setlist_display_adtldatatype_4_target: pageId } = settings;

	return models.map(model => ({
		name: model.name,
		routeLink: pageId
			? replacePlaceholdersInPageSlug(globalConfig, pageId, {
					':id': model.cms_data_value_id,
					':slug': model.slug,
				}) || null
			: null,
	}));
}
