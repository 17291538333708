import { NatsSiteData } from '@nats/models';

/**
 * Replaces placeholders in the page slug
 *
 * @example
 * replacePlaceholdersInPageSlug(config, '123', { ':id': '456' });
 *
 * @param config
 * @param pageId
 * @param toBeReplaced
 */
export function replacePlaceholdersInPageSlug(
	config: NatsSiteData,
	pageId: string,
	toBeReplaced: Record<string, string> = {}
): string {
	let pageSlug = config.pages.find(x => `${x.cms_page_id}` === `${pageId}`)?.slug;

	if (pageSlug) {
		Object.keys(toBeReplaced).forEach(key => {
			pageSlug = pageSlug?.replace(key, toBeReplaced[key]);
		});
	}

	return pageSlug || '';
}
