/**
 * Adds the Google Analytics script tag to the body
 * and configures the analytics with the given tracking id.
 * @param trackingId
 */
export const initializeGoogleAnalytics = (trackingId?: string | null) => {
	if (!trackingId) {
		return;
	}
	configureGoogleAnalytics(trackingId);
};

function configureGoogleAnalytics(trackingId: string) {
	// add the script tag to the body
	const scriptLinkEl = document.createElement('script');
	scriptLinkEl.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
	scriptLinkEl.async = true;
	document.body.appendChild(scriptLinkEl);

	// add the analytics config to the body
	const scriptEl = document.createElement('script');
	scriptEl.textContent = `
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag("js", new Date());
		gtag("config", "${trackingId}");
	`;
	document.body.appendChild(scriptEl);
}
