import { InjectionToken } from '@angular/core';
import {
	NatsBlock,
	NatsPageData,
	NatsSiteData,
	PageCustomData,
	PageSettingsDesignLayout,
	UserInfo,
} from '@nats/models';

export interface NatsConfig {
	natsUrl: string;
	areaUrl: string;
	login_url: string;
	cms_area_id: string;
	type: string;
	tokenLocation: string;
	idTokenPath: string[];
	accessTokenPath: string[];
}

export const SITE_TYPES = {
	AREA: 'area',
	TOUR: 'tour',
};

// Token that will tell the component type of the site: tour or area
export const SITE_TYPE = new InjectionToken<'tour' | 'area'>('Site type');

// Token that will be used to pass data to each block component based on page
export const BLOCK_DATA = new InjectionToken<NatsBlock>('Dynamic Component Data');

// Will be used to pass Auth data to block components
export const AUTH_DATA = new InjectionToken<UserInfo>('Auth Data');

// Data that are specific for each page. ex: /photos will have different settings from /models
export const PAGE_DATA = new InjectionToken<NatsPageData>('Current Page Data');

// Data that are specific for each page. Ex. Used to determine if the page is a model view page or set view page etc.
export const PAGE_CUSTOM_DATA = new InjectionToken<PageCustomData>('Current Page Custom Data');

// Will be provided by component loader and will have the NATS backend api url
export const API_URL = new InjectionToken<string>('Api Url token');

// NATS code used for tracking
export const NATS_CODE = new InjectionToken<string | null>('NATS code used for tracking');

// Global app configuration data
export const GLOBAL_CONFIG = new InjectionToken<NatsSiteData>('Global config data token');

// Config data that are needed to initialize the application
export const CONFIG_TOKEN = new InjectionToken<NatsConfig>('App initialization config data token');

// Page design layout data
export const PAGE_DESIGN_LAYOUT = new InjectionToken<PageSettingsDesignLayout>(
	'Settings design layout'
);
