import {
	FormattedContentData,
	NatsContent,
	NatsSet,
	NatsSiteData,
	VideoNatsContent,
} from '@nats/models';
import { createContentUrl } from './create-content-url';

export interface SetVideoData {
	name: string; // name of video file (original name)
	thumbnail: string;
	cms_content_id: string;
	formats?: {
		url: string;
		downloadUrl: string;
		name: string;
		format: string;
		resolution_y: string;
		orientation: string[]; //'landscape', 'portrait', 'square'
		cms_format_id: string;
	}[];
}

export function getSetVideos(set: NatsSet, config: NatsSiteData): SetVideoData[] {
	const videoContent = set.content.filter(x => x.content_type === 'video');

	if (videoContent.length === 0) return [];

	const setVideoData: SetVideoData[] = [];

	for (const content of videoContent) {
		assertIsVideoNatsContent(content);

		const payload: SetVideoData = {
			name: content.name,
			thumbnail: getLargestThumbnailUrl(content.thumb, config),
			cms_content_id: content.cms_content_id || '',
			formats: content.content
				.filter(x => {
					return (
						x.format !== 'source' &&
						x.cms_format_id !== '' &&
						!(x instanceof Array) &&
						(x.content?.length > 0 || x.dl_content?.length > 0) // if we only want to stream or download
					);
				}) // Filter out source format
				.map(item => {
					return {
						name: item.format,
						format: item.format || '',
						resolution_y: item.resolution_y || '',
						url: item.content ? getVideoUrl(item.content[0], config) : '',
						downloadUrl: item.dl_content ? getVideoUrl(item.dl_content[0], config) : '',
						cms_format_id: item.cms_format_id || '',
						orientation: [
							item.orientation_horizontal === '1' ? 'landscape' : '',
							item.orientation_vertical === '1' ? 'portrait' : '',
							item.orientation_square === '1' ? 'square' : '',
						].filter(x => x !== ''),
					};
				}),
		};

		setVideoData.push(payload);
	}

	return setVideoData;
}

/**
 * Get video url from format data
 * @param format
 * @param config
 */
function getVideoUrl(format: FormattedContentData, config: NatsSiteData): string {
	const { signature, fileuri, cms_content_server_id } = format;
	if (!signature?.length || !cms_content_server_id || !fileuri) return '';

	const { url } = config.servers[cms_content_server_id].settings;
	const normalizedServerUrl = url.endsWith('/') ? url.slice(0, -1) : url;
	return `${normalizedServerUrl}${fileuri}?${signature}`;
}

/**
 * Get largest thumbnail url
 * @param thumb
 * @param config
 */
export function getLargestThumbnailUrl(
	thumb: Record<string, FormattedContentData[]>,
	config: NatsSiteData
): string {
	const largestThumb = Object.keys(thumb).reduce(
		(acc, key) => {
			const size = +key.split('-')[0]; // ex: 150-150, 400-0, 800-0
			if (size > acc.size) {
				return { size, content: thumb[key] };
			}
			return acc;
		},
		{ size: 0, content: [] } as { size: number; content: FormattedContentData[] }
	);

	if (largestThumb.size === 0 || !largestThumb.content.length) return '';

	return createContentUrl(largestThumb.content[0], config);
}

function assertIsVideoNatsContent(setContent: NatsContent): asserts setContent is VideoNatsContent {
	if (setContent.content_type !== 'video') {
		throw new Error('setContent is not an video');
	}
}
