import { ApplicationRef, Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initializeGoogleAnalytics } from '@nats/shared/functions';
import { filter, take } from 'rxjs';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { LAZY_BLOCKS_MAP } from './blocks/blocks-map';

@Component({
	selector: 'nats-tours-root',
	template: ` <router-outlet /> `,
	standalone: true,
	imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
	constructor() {
		const siteData = inject(GLOBAL_CONFIG);
		const appRef = inject(ApplicationRef);

		appRef.isStable
			// wait for the app to be stable at least once before initializing GA
			.pipe(filter(Boolean), take(1))
			.subscribe(() => initializeGoogleAnalytics(siteData.area.settings.ga_trackingid));
	}

	ngOnInit() {
		preloadBlocks();
	}
}

function preloadBlocks() {
	setTimeout(() => {
		requestIdleCallback(() =>
			Object.values(LAZY_BLOCKS_MAP).forEach(importFn => {
				importFn().then();
			})
		);
	}, 8000); // 8 seconds
}
