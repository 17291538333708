import { NatsBlock } from './nats-block.model';

export interface PageCustomData {
	/**
	 * This will be true if the page contains a model view block
	 */
	isModelViewPage?: boolean;

	/**
	 * This will be true if the page contains a set view block
	 */
	isSetViewPage?: boolean;
}

export function isModelViewPage(pageBlocks: NatsBlock[]): boolean {
	return pageBlocks.some(block => block.settings.type === 'model_view');
}

export function isSetViewPage(pageBlocks: NatsBlock[]): boolean {
	return pageBlocks.some(block => block.settings.type === 'set_view');
}
