import { FormattedContentData, ImageNatsContent, NatsContent } from '@nats/models';

export function getContentImageData(setContent: NatsContent): {
	full: Record<string, FormattedContentData[]> | null;
	preview: Record<string, FormattedContentData[]> | null;
	identifier?: string;
} {
	assertIsImageNatsContent(setContent);

	const { content, thumb } = setContent;
	if (!content?.length) return { full: null, preview: null };
	const full = { '800-0': content };

	// use the first fileuri as the identifier
	const identifier = content[0].fileuri;

	return { full, preview: thumb, identifier };
}

function assertIsImageNatsContent(setContent: NatsContent): asserts setContent is ImageNatsContent {
	if (setContent.content_type !== 'image') {
		throw new Error('setContent is not an image');
	}
}
