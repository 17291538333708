import { isDevMode } from '@angular/core';

declare let gtag: (...args: unknown[]) => void;

/**
 * Calls the callback function when the browser is idle.
 * @param callback
 */
function onIdle(callback: () => void) {
	if ('requestIdleCallback' in window) {
		window.requestIdleCallback(callback);
	} else {
		callback();
	}
}

let loggedGTMIsNotInitialized = false;
function track(...args: unknown[]) {
	if (typeof gtag !== 'function') {
		if (!loggedGTMIsNotInitialized) {
			loggedGTMIsNotInitialized = true;
			if (isDevMode()) {
				console.warn('Google Analytics is not initialized.');
			}
		}
	} else {
		gtag(...args);
	}
}

export const GTM_EVENTS = {
	filter: (type: 'set' | 'model' | 'data-value', filterBy: string, value?: string | number) => {
		onIdle(() =>
			track('event', 'filter', {
				event_category: 'filter',
				event_label: 'Filter',
				value: `Type: ${type}, FilterBy: ${filterBy} ${value ? `, Value: ${value}` : ''}`,
			})
		);
	},
	sort: (type: 'set' | 'model' | 'data-value', direction: string) => {
		onIdle(() =>
			track('event', 'sort', {
				event_category: 'sort',
				event_label: 'Sort',
				value: `Type: ${type}, Direction: ${direction}`,
			})
		);
	},
	search: (searchValue: string) => {
		onIdle(() =>
			track('event', 'search', {
				event_category: 'search',
				event_label: 'Search',
				value: `SearchValue: ${searchValue}`,
			})
		);
	},
	videoPlayed: (setId: string, id: string | number) => {
		onIdle(() =>
			track('event', 'video_played', {
				event_category: 'video',
				event_label: 'Video Played',
				value: `SetID: ${setId}, VideoID: ${id}`,
			})
		);
	},
	videoPaused: (setId: string, id: string | number) => {
		onIdle(() =>
			track('event', 'video_paused', {
				event_category: 'video',
				event_label: 'Video Paused',
				value: `SetID: ${setId}, VideoID: ${id}`,
			})
		);
	},
	videoDownloaded: (setId: string, url: string | number) => {
		onIdle(() =>
			track('event', 'video_downloaded', {
				event_category: 'video',
				event_label: 'Video Downloaded',
				value: `SetID: ${setId}, Video: ${url}`,
			})
		);
	},
	// videoChangeResolution: (setId: string, id: string | number, resolution: string) => {
	// 	onIdle(() =>
	// 		track('event', 'video_change_resolution', {
	// 			event_category: 'video',
	// 			event_label: 'Video Change Resolution',
	// 			value: `SetID: ${setId}, VideoID: ${id}, Resolution: ${resolution}`,
	// 		})
	// 	);
	// },
	photoViewed: (setId: string, id: string | number) => {
		onIdle(() =>
			track('event', 'photo_viewed', {
				event_category: 'photo',
				event_label: 'Photo Viewed',
				value: `SetID: ${setId}, PhotoID: ${id}`,
			})
		);
	},
	photosDownloaded: (setId: string) => {
		onIdle(() =>
			track('event', 'photos_downloaded', {
				event_category: 'photo',
				event_label: 'Photos Downloaded',
				value: `SetID: ${setId}`,
			})
		);
	},
	commentOnSet: (setId: string) => {
		onIdle(() =>
			track('event', 'comment_on_set', {
				event_category: 'comment',
				event_label: 'Comment On Set',
				value: `SetID: ${setId}`,
			})
		);
	},
};
