import { FormattedContentData, NatsSiteData } from '@nats/models';

export interface ContentUrlWithDimensions {
	width: number;
	height: number;
	urls: string[];
}

export const createContentUrlFromThumb = (
	data: { [key: string]: FormattedContentData[] },
	siteData: NatsSiteData
): Array<ContentUrlWithDimensions> => {
	const contentUrls: Array<{ width: number; height: number; urls: string[] }> = [];

	// key is like: '200-340', '400-400', '800-500', '1200-0'
	for (const key in data) {
		const items = data[key];
		const size = key.split('-'); // ['200', '340']
		const urls = items.map(x => createContentUrl(x, siteData)).filter(x => x !== ''); // remove empty urls

		contentUrls.push({ width: parseInt(size[0]), height: parseInt(size[1]), urls });
	}

	return contentUrls;
};

/**
 * Creates a content url for a given content data object and site data
 *
 * @param data
 * @param siteData
 */
export const createContentUrl = (data: FormattedContentData, siteData: NatsSiteData): string => {
	const { cms_content_server_id, fileuri, signature } = data;

	// Get the content server url from the site data
	if (!siteData.servers[cms_content_server_id]) {
		return '';
	}

	const serverUrl = siteData.servers[cms_content_server_id].settings.url;

	// {{serverdomain}}/{{fileuri}}?{{signature}}
	const normalizedServerUrl = serverUrl.endsWith('/') ? serverUrl.slice(0, -1) : serverUrl;
	return `${normalizedServerUrl}${fileuri}?${signature}`;
};
