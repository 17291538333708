import { AdditionalDataValuePreview, FormattedContentData, ModelDataValue } from '@nats/models';

export function getModelThumbnailAndBanner(
	model: ModelDataValue,
	blockConfig: { settings: { [key: string]: string } }
): {
	thumbnail: Record<string, FormattedContentData[]> | null;
	banner: Record<string, FormattedContentData[]> | null;
} {
	const thumbnailKey = 'adtldatavalue_4_datafield_thumbnail';
	const bannerKey = 'adtldatavalue_4_datafield_banner';

	const thumbnailId = blockConfig.settings[thumbnailKey];
	const bannerId = blockConfig.settings[bannerKey];

	let thumbnail: Record<string, FormattedContentData[]> | null = null;
	let banner: Record<string, FormattedContentData[]> | null = null;

	if (!thumbnailId || !bannerId || !model || !model['data_detail_values'])
		return { thumbnail, banner };

	const previewField = model.data_detail_values?.['preview'];
	if (isPreviewField(previewField)) {
		thumbnail = previewField[thumbnailId];
		banner = previewField[bannerId];
	}

	return { thumbnail, banner };
}

function isPreviewField(previewField: unknown): previewField is AdditionalDataValuePreview {
	return !!previewField;
}
