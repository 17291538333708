import { AdditionalDataValuePreview, FormattedContentData } from '@nats/models';

export function getDataDetailListThumbImageContent(
	model: any /* DataValue */,
	dataDetailId?: string
): Record<string, FormattedContentData[]> | null {
	if (!model.data_detail_values) return null;

	const previewField = model.data_detail_values['preview'] as AdditionalDataValuePreview;
	if (!previewField) return null;
	if (dataDetailId && previewField[dataDetailId]) return previewField[dataDetailId];
	return Object.values(previewField)?.[0] || null;
}
