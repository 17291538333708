import { BlockThumbnailsPerRowSettings } from '@nats/models';

export const getThumbnailsPerRow = (settings: BlockThumbnailsPerRowSettings) => {
	const {
		thumbnails_per_row_xxl: xxl,
		thumbnails_per_row_xl: xl,
		thumbnails_per_row_lg: lg,
		thumbnails_per_row_md: md,
		thumbnails_per_row_sm: sm,
		thumbnails_per_row_xs: xs,
	} = settings;

	return {
		xs: 12 / +(xs ?? 1), // col-12
		sm: 12 / +(sm ?? 2), // col-sm-6
		md: 12 / +(md ?? 3), // col-md-4
		lg: 12 / +(lg ?? 4), // col-lg-3
		xl: 12 / +(xl ?? 4), // col-xl-3
		xxl: 12 / +(xxl ?? 4), // col-xxl-3
	};
};

export const getThumbnailsPerRowClasses = (settings: BlockThumbnailsPerRowSettings) => {
	const sizes = getThumbnailsPerRow(settings);
	return `col-${sizes.xs} col-sm-${sizes.sm} col-md-${sizes.md} col-lg-${sizes.lg} col-xl-${sizes.xl} col-xxl-${sizes.xxl}`;
};
