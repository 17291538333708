import {
	BlockConfig,
	BREAKPOINT_MAP,
	DesignLayout,
	PageDesignLayout,
	PageHiddenBlocks,
	SizeScreenType,
} from '@nats/models';

/**
 * Styles for grid layout will be created based on the blockConfig array.
 *
 * @media (min-width: 0px) and (max-width: 767px) { // Phone
 *  	#block-{{BlockId}}-layout {
 * 			grid-column-start: 1;
 * 			grid-column-end: 13;
 * 			grid-row-start: 1;
 * 			grid-row-end: 3;
 * 	 	}
 * 	   // ...
 * 	}
 *
 * 	@media (min-width: 992px) { // Desktop
 *  	#block-{{BlockId}}-layout {
 * 			grid-column-start: 1;
 * 			grid-column-end: 13;
 * 			grid-row-start: 1;
 * 			grid-row-end: 3;
 * 	 	}
 * 	   // ...
 * 	}
 */

export const createPageGridLayoutStyles = (
	pageDesignLayout: PageDesignLayout,
	blocksData: BlockConfig[],
	hiddenBlocks: PageHiddenBlocks,
	pageId: string
): string => {
	const stylesBasedOnScreenSize = (screenSize: SizeScreenType): string => {
		if (!pageDesignLayout[screenSize] || pageDesignLayout[screenSize].length === 0) {
			return blocksData.reduce((acc, block, index) => {
				const blockDefaultLayout = createBlockDefaultLayout(block.id, index);
				return `${acc} ${createStylesBasedOnDesignLayout(blockDefaultLayout, pageId)}`;
			}, '');
		}

		return pageDesignLayout[screenSize].reduce((acc, designLayout) => {
			if (hiddenBlocks[screenSize]?.includes(designLayout.id)) {
				return `${acc} ${createStylesWithDisplayNone(designLayout, pageId)}`;
			}
			return `${acc} ${createStylesBasedOnDesignLayout(designLayout, pageId)}`;
		}, '');
	};

	const stylesForDesktop = stylesBasedOnScreenSize(SizeScreenType.Desktop);

	let stylesForPhone = '';

	if (
		!pageDesignLayout[SizeScreenType.Phone] ||
		pageDesignLayout[SizeScreenType.Phone].length === 0
	) {
		stylesForPhone = pageDesignLayout[SizeScreenType.Desktop]
			.sort((a, b) => {
				if (a.y === b.y) return a.x - b.x; // if they have the same y, sort them by x (left to right)
				return a.y - b.y; // sort them by y (top to bottom)
			}) // sort them by coordinates
			.reduce((acc, designLayout, index) => {
				if (hiddenBlocks[SizeScreenType.Desktop].includes(designLayout.id)) {
					return `${acc} ${createStylesWithDisplayNone(designLayout, pageId)}`;
				}
				// create default layout for phone based on desktop layout coordinates
				const blockDefaultLayout = createBlockDefaultLayout(
					designLayout.id,
					designLayout.y + index
				);

				blockDefaultLayout.h = 1; // set height to 1 for phone layout
				return `${acc} ${createStylesBasedOnDesignLayout(blockDefaultLayout, pageId)}`;
			}, '');
	} else {
		stylesForPhone = stylesBasedOnScreenSize(SizeScreenType.Phone);
	}

	return `
		@media ${BREAKPOINT_MAP[SizeScreenType.Phone]} { ${stylesForPhone} }
		@media ${BREAKPOINT_MAP[SizeScreenType.Desktop]} { ${stylesForDesktop} }
	`;

	// this will return sth like:
	// @media (min-width: 0px) and (max-width: 767px) {
	// 	#block-{{BlockId}}-page-{{PageId}}-layout { // ... }
	// }
	// @media (min-width: 767px) {
	// 	#block-{{BlockId}}-page-{{PageId}}-layout { // ... }
	// }
};

const createStylesBasedOnDesignLayout = (designLayout: DesignLayout, pageId: string): string => {
	const { id: blockId, x, y, w, h } = designLayout;
	return `#block-${blockId}-page-${pageId}-layout {
				grid-column-start: ${x + 1};
				grid-column-end: ${x + w + 1};
				grid-row-start: ${y + 1};
				grid-row-end: ${y + h + 1};
			}`;
};

const createStylesWithDisplayNone = ({ id: blockId }: DesignLayout, pageId: string): string => {
	return `#block-${blockId}-page-${pageId}-layout { display: none; }`;
};

export const createBlockDefaultLayout = (id: string, index: number): DesignLayout => {
	return { id, x: 0, y: index, w: 12, h: 1, minW: 2, minH: 1 };
};
