import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';

/*
 * This function is used to inject scrollTo function.
 * It is used to scroll to a specific element on the page.
 *
 * Usage:
 * const scrollTo = injectScrollTo(); // should be injected in construction context
 * scrollTo('some-id');
 */
export const injectScrollTo = () => {
	const platformId = inject(PLATFORM_ID);

	// don't scroll on server side
	if (isPlatformServer(platformId)) return () => 0;

	return (id: string, options: ScrollIntoViewOptions = {}) => {
		const element = document.getElementById(id);
		element?.scrollIntoView({ block: 'start', ...options });
	};
};
